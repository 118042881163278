<template>
  <div>
    <section class="content">
      <div class="row">
        <div class="col-sm-12">
          <div class="box box-default box-solid">
            <div class="box-body table-responsive  listing-grid-container">
              <grid
                :service="service"
                :source="gridDataSource"
                :name="gridName"
                :columns="gridColumns"
                :actions="gridActions"
                :default-order="[3, 'desc']"
                :data-source-method="gridDataSourceMethod"
                :additional-params="additionalParams"
                @show="actionShow"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
  import Grid from './Grid.vue'
  import moment from 'moment'
  import DynamicBreadcrumb from "@/components/bundles/share/mixins/DynamicBreadcrumb";
import clientObjectMixin from '../share/mixins/clientObjectMixin.js'

  export default {
    name: 'Complaints',
    mixins: [
      DynamicBreadcrumb,
      clientObjectMixin

    ],
    components: {
      Grid
    },
    data () {
      return {
        picker: new Date().toISOString().substr(0, 10),
        events: {},
        addSettlementModalVisible: false,
        gridActions: [],
        gridColumns: [],
        gridArchivedColumns: [],
        gridDataSourceMethod: 'post',
        gridDataSource: {},
        gridName: 'grid_complaints',
        archiveGridName: 'grid_complaints_archive',
        service: this.$route.params.service,
      }
    },
    computed: {
      secondMenu () {
        return [
        {
            event: { name: `complaints:add` },
          icon: 'plus',
            label: 'Dodaj reklamację',
            acl: { action: 'post_complaint', service: this.service }
        },
      ]
    },
    client () {
        return this.$route.params.client
      },
    clientObject () {
        return this.$store.state.base.user.serviceClients[this.service].find(client => parseInt(client.id) === parseInt(this.$route.params.client))
      },
    categoryGroup () {
      return this.$getCategoryGroupNew(this.clientObject, this.$route.params.taskCategoryId)
    },
    categoryName () {
      return this.$getCategoryNew(this.clientObject, this.$route.params.taskCategoryId).name
    },
    categoryLabel () {
      return this.$getCategoryNew(this.clientObject, this.$route.params.taskCategoryId).label
    },
    categoryId () {
      return this.$getCategoryNew(this.clientObject, this.$route.params.taskCategoryId).id
    },
      additionalParams () {
      if (this.$isWithClients(this.service)) {
          let data = {
            name: this.gridName,
            client: this.client,
            clientUuid: this.clientObject.uuid,
            type: this.categoryGroup,
            categoryId: this.categoryId,
          }
          data['categoryId'] = this.getCategory(this.clientObject, this.$route.params.taskCategoryId).id
          data['task_category'] = this.getCategory(this.clientObject, this.$route.params.taskCategoryId).name
        return data
      }
      return false
      }
    },
    created () {
      this.getGridColumns()
    },
    mounted () {
      this.getEvents()
      this.getGridArchivedColumns()
      this.getGridDataSource(this.service)
      this.getGridActions()
      this.$events.on(this.events.addSettlement, this.addSettlement)
      this.addDynamicBreadcrumbLabel(`complaints`, `${this.$getClient(this.service, this.client).label} - ${this.categoryLabel}`)
    },
    methods: {
      getCategoryGroup (Client, categoryId) {
          return Object.entries(Client.taskCategories).find(([key, value])  => value.find(category => parseInt(category.id) === parseInt(categoryId)))[0]
      },
      getCategory (Client, categoryId) {
        const group = this.getCategoryGroup(Client, categoryId)
        return Client.taskCategories[group].find(category => parseInt(category.id) === parseInt(categoryId))
      },
      getEvents () {
        this.events = {
          addSettlement: `${this.service}:add`
        }
      },
      getGridActions () {
        this.gridActions = [
          {
            id: 0,
            event: 'show',
            title: 'Szczegóły',
            icon: 'eye',
            params: ['id'],
            acl: {
              service: this.service,
              action: [`get_tasks_datatables__default__complaint_${this.client}`, `get_tasks_datatables__default__complaint_${this.client}`],
              allowOneOfActions: true
            },
            routeTo: {
            name: `complaint`,
            params: { taskId: 'id' }
          },
          router: () => this.$router
          }
      
        ]
      },
      getGridDataSource (service) {
        this.gridDataSource = {
          service: this.service,
          url: `/tasks/datatables`
        }
      },
      getGridColumns () {
        let vm = this
        this.gridColumns = [
          {
            id: 0,
            data: 'id',
            title: 'Numer'
          },
          {
            id: 1,
            data: 'number',
            title: 'Zlecenie'
          },
          {
            id: 2,
            data: 'createdAt',
            title: 'Utworzenie',
            render (data) {
              return vm.formatDate(data)
            }
          },
          {
            id: 3,
            data: 'lastClosedAt',
            title: 'Zamknięcie',
            render (data) {
              return vm.formatDate(data)
            }
          },
          {
            id: 4,
            data: 'state',
            title: 'Status'
          },
          {
            id: 5,
            data: 'taskStates',
            title: 'Status zleceń'
          },
          {
            id: 6,
            data: 'assignee',
            title: 'Opiekun zlecenia',
          },
          {
            id: 7,
            data: 'appraiser',
            title: 'Rzeczoznawca',
          },
          {
            id: 8,
            data: 'orderId',
            title: 'Numer banku',
          },
        ]
      },
      getGridArchivedColumns () {
        this.gridArchivedColumns = []
      },
      formatDate (data) {
        if (data) {
          return moment(data).locale('pl').format('YYYY-MM-DD kk:mm:ss')
        } else {
          return ''
        }
      },
      formatDateWithoutTime (data) {
        if (data) {
          return moment(data).locale('pl').format('YYYY-MM-DD')
        } else {
          return ''
        }
      },
      addSettlement () {
        this.addSettlementModalVisible = true
      },
      actionShow (id, params) {
        if (params && params.id) {
          return this.$router.push({
            name: `complaint`,
            params: { taskId: id, service: this.service, client: this.client, taskCategoryId: this.$route.params.taskCategoryId }
            }
          )
        }
      }
    }
  }
</script>

<style>
.listing-grid-container {
  transform:rotateX(180deg)
}
.listing-grid-container > * {
  transform:rotateX(180deg)
}
</style>
